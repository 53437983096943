import React from "react"

const PageContent = () => {
  return (
    <div className="container">
      <div className="page-content">
        <p>
          The Cojafex Induction pipe bending process utilises precisely
          positioned inductors to gradually heat steel by method of induction.
          Through this method the bending process is initiated by creating a
          narrowly focused heat band which gradually softens the metal section
          at the desired radius that is to be shaped. This process of induction
          bending ensures that none of the adverse metallurgical effects often
          associated with traditional pipe bending techniques are apparent.
        </p>
        <p>
          The induction bending process involves a strait pipe being placed on
          the machine bed with one end clamped to a free pivoting arm at the
          pre-determined radius. As the pipe reaches the required temperature it
          is slowly propelled through the coil towards the bending arm at a
          perfectly maintained speed.
        </p>
        <p>
          The pipe bending process takes place at the precisely set position at
          which the induction coil is located and throughout the entire bending
          process the thermo-mechanical characteristics of the coil are
          precisely monitored and recorded by the machine.
        </p>
        <p>
          Depending on material requirements, the bent pipe that emerges from
          the induction coil is carefully cooled gradually using either air or
          water. This process is carefully maintained and monitored to ensure
          that the metallurgical integrity of the pipe it consistent and that it
          maintains its characteristics.
        </p>
        <p>Induction bending areas of application:</p>

        <ul style={{ padding: 0, listStyle: "none" }}>
          <li>Chemical and petrochemical</li>
          <li>Oil and gas</li>
          <li>Power generation</li>
          <li>Shipbuilding</li>
          <li>Construction</li>
          <li>Offshore and pipeline</li>
          <li>
            Compressor and pump stationsCommon materials used for Induction
            bending:
          </li>
          <li>Carbon steels</li>
          <li>Stainless steels</li>
          <li>Special alloys</li>
          <li>Aluminium</li>
          <li>Titanium</li>
        </ul>

        <h1>Advantages of Induction Bending</h1>
        <p>
          Induction bending provides a cost effective solution, by reducing the
          need for elbows induction bends can be produced more quickly than most
          standard components can be welded. By utilising larger radius bends
          elbows can be more readily replaced which reduces friction, wear and
          required pump capacity. With uniform wall thickness achieved through
          induction bending stock of elbows and standard bends can be greatly
          reduced which will further reduce the time to market.
        </p>
      </div>
    </div>
  )
}

export default PageContent
