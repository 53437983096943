import React from "react"

import Layout from "../../components/layout"
import Hero from "../../components/hero"
import PageContent from "../../page-views/induction-bending/cojafex-induction-bending-process"
import BgImage from "../../assets/images/2017/08/heat-treating-bends-slide.jpg"

const CojafexProcessPage = () => {
  const title = "Cojafex Induction Bending Process"
  const content = "The art of induction bending"

  return (
    <Layout>
      <Hero title={title} content={content} bgImage={BgImage} />
      <PageContent />
    </Layout>
  )
}

export default CojafexProcessPage
